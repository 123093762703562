<template>
	<div id="supplierSettlement">
		<div class="top">
			<div class="title">供应商结算<i></i></div>
		</div>
		<el-form class="slForm" ref="slForm" :model="slForm" :rules="rules" label-position="top"
			:disabled="slForm.status=='SUBMIT'||slForm.status=='SUCCESS'||slForm.status=='FINISH'" v-loading="loading"
			:element-loading-text="loadingText">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item prop="collectWay">
						<template #label>
							收款方式
							<el-popover placement="right" width="400" trigger="hover">
								<h3>请仔细阅读以下说明</h3>
								<p>如有<span>一次性收款</span>，直接选择“一次性收款并结项”</p>
								<p>如有<span>分三次及三次以上收款</span>，第一次选择“申请首次收款”，第二次选择“申请尾款”</p>
								<p>如有<span>分三次及三次以上收款</span>，第一次选择“申请首次收款”，中间付款选择“申请阶段收款”，最后一笔选择“申请尾款”</p>
								<p><span>据实结算</span>的收款方式仅支持费用类型是会议类，框架服务模式，按场次收款的项目，可多次申请</p>
								<i class="el-icon-info" slot="reference"></i>
							</el-popover>
						</template>
						<!-- {{collectWayEnums}} -->
						<el-select v-model="slForm.collectWay" placeholder="请选择收款方式" @change="getProjectConfigFun"
							disabled>
							<el-option v-for="(item,i) in collectWayEnums" :key="i" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="feeType">
						<template #label>费用类型</template>
						<el-select v-model="slForm.feeType" placeholder="请选择收款方式" disabled>
							<el-option v-for="(item,i) in feeTypeEnums" :key="i" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="projectName">
						<template #label>项目名称</template>
						<el-input v-model="slForm.projectName" placeholder="请输入项目名称" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8" v-if="slForm.lastDetail">
					<el-form-item prop="lastUsedMoney">
						<template #label>上阶段收款金额</template>
						<el-input disabled type="number" v-model.number="slForm.lastUsedMoney"
							placeholder="请完善上阶段结算明细"><template slot="append">元</template></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="applyMoney">
						<template #label>本次收款金额</template>
						<el-input disabled type="number" v-model.number="slForm.applyMoney"
							placeholder="请完善结算明细"><template slot="append">元</template></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="supplierName">
						<template #label>供应商名称</template>
						<el-input disabled v-model="slForm.supplierName" placeholder="请输入供应商名称"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="receiptType">
						<template #label>发票类型</template>
						<el-select v-model="slForm.receiptType" placeholder="请选择收款方式">
							<el-option v-for="(item,i) in receiptTypeEnums" :key="i" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="deliveryNum">
						<template #label>快递单号</template>
						<el-input v-model="slForm.deliveryNum" placeholder="邮寄纸质材料快递单号 (若无不填写)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item prop="meetingIds">
						<template #label>本次结算的会议场次</template>
						<el-select v-model="slForm.meetingIds" placeholder="请选择您要结算的会议" clearable multiple
							:collapse-tags="false" :disabled="meetingId&&false">
							<el-option v-for="item in meetingList" :key="item.id" :label="item.meetingTitle"
								:value="item.id">
								{{item.meetingTitle}} / {{$moment(item.updateTime).format('lll')}}
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="24">
					<el-form-item>
						<template #label>
							支出明细
							<!-- <b class="titB">本次结算</b> -->
						</template>
						<div class="options">
							<div class="first">
								<!-- <el-link @click="downloadTemplate()" type="primary"
									icon="el-icon-download">下载结算单模版</el-link>
								<el-upload :action="`${baseUrl}/sign/live/assistant/getSheetData`"
									:show-file-list="false" multiple :headers="{token:token}"
									:on-success="importTheStatement" :before-upload="beforeUploadFun">
									<el-link type="success" icon="el-icon-upload2">导入结算单</el-link>
								</el-upload>
								<span class="tip">(导入为追加导入 以最新下载的结算单模板为准)</span> -->
							</div>
							<!-- <el-button type="primary" @click="saveInformation('slForm')">保存信息</el-button> -->
							<el-button type="primary" @click="saveInformation2('slForm')">保存信息</el-button>
						</div>
						<el-table :data="slForm.spendingDetailDtos" style="width: 100%">
							<el-table-column label="序号" width="100" type="index" align="center"></el-table-column>
							<el-table-column label="支出明细" align="center">
								<template slot-scope="scope">
									<el-select v-model.async="scope.row.spendingProjectCode"
										placeholder="请选择收款方式(支持关键字搜索)"
										:disabled="scope.row.spendingProjectCode=='J0000'||scope.row.spendingProjectCode=='UM000'||scope.row.detailType=='L'"
										filterable clearable>
										<template v-if="false">
											<el-option v-for="item in projectTree['0']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template
											v-if="slForm.collectWay=='0'||slForm.collectWay=='2'|| slForm.collectWay=='4'|| slForm.collectWay=='3'">
											<el-option v-for="item in projectTree['1']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template v-if="slForm.collectWay=='1'">
											<el-option v-for="item in projectTree['2']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template v-if="slForm.collectWay=='2'||slForm.collectWay=='3'">
											<el-option v-for="item in projectTree['3']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
									</el-select>
								</template>
							</el-table-column>

							<el-table-column label="金额(元)" align="center">
								<template slot-scope="scope">
									<el-input type="number" v-model.number="scope.row.money" placeholder="仅支持数字及小数点后2位"
										:min="0" @input="moneyChange(scope.$index,$event)"
										:disabled="scope.row.spendingProjectCode=='UM000'">
										<template slot="append">元</template>
									</el-input>
								</template>
							</el-table-column>
							<el-table-column label="状态" align="center" width="150" v-if="slForm.status">
								<template slot-scope="scope">
									{{ folderStatus[slForm.status] }}
								</template>
							</el-table-column>
							<el-table-column width="200" align="center"
								v-if="slForm.status=='SUBMIT'||slForm.status=='SUCCESS'||slForm.status=='FINISH'">
								<template slot="header" slot-scope="scope">
									操作
									<span style="color: red;">(请先保存信息后再上传附件)</span>
								</template>
								<template slot-scope="scope">
									<el-link @click="goUploadFile(scope.row)"> 去查看</el-link>
								</template>
							</el-table-column>
							<el-table-column width="250" align="center" v-else>
								<template slot="header" slot-scope="scope">
									操作
									<span style="color: red;">(请先保存信息后再上传附件)</span>
								</template>
								<template slot-scope="scope">
									<!-- {{scope.row.spendingProjectCode}} -->
									<!-- <el-button
										v-if="scope.row.spendingProjectCode!='UM000'&&scope.row.spendingProjectCode!='J0000'"
										type="text" @click="deleteRows(scope.$index)">删除行</el-button> -->
									<el-button v-if="scope.row.id" type="text"
										@click="goUploadFile(scope.row)">去上传文件</el-button>
									<span v-else style="color: red;margin-left: 10px;">(请先保存信息后再上传附件)</span>
								</template>
							</el-table-column>

						</el-table>
						<div class="subtotal">合计本次结算金额： {{slForm.applyMoney?slForm.applyMoney:0}}元</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20" v-if="slForm.lastDetail">
				<el-col :span="24">
					<el-form-item>
						<template #label>
							支出明细
							<b class="titB">上阶段结算明细</b>
						</template>
						<el-table :data="slForm.lastDetail" style="width: 100%">
							<el-table-column label="序号" width="100" type="index" align="center"></el-table-column>
							<el-table-column label="支出明细" align="center">
								<template slot-scope="scope">
									<el-select v-model.async="scope.row.spendingProjectCode"
										placeholder="请选择收款方式(支持关键字搜索)"
										:disabled="scope.row.spendingProjectCode=='J0000'||scope.row.spendingProjectCode=='UM000'"
										filterable clearable>
										<template v-if="false">
											<el-option v-for="item in projectTree['0']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template
											v-if="slForm.collectWay=='0'||slForm.collectWay=='2'||slForm.collectWay=='3'||slForm.collectWay=='4'">
											<el-option v-for="item in projectTree['1']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template v-if="slForm.collectWay=='1'">
											<el-option v-for="item in projectTree['2']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
										<template v-if="slForm.collectWay=='2'||slForm.collectWay=='3'">
											<el-option v-for="item in projectTree['3']" :key="item.code"
												:label="item.projectType" :value="item.code"
												:disabled="slForm.spendingDetailDtos.map(item=>item.spendingProjectCode).includes(item.code)"></el-option>
										</template>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column label="金额(元)" align="center">
								<template slot-scope="scope">
									<el-input type="number" v-model.number="scope.row.money" placeholder="仅支持数字及小数点后2位"
										:min="0" @input="lastMoneyChange(scope.$index,$event)"
										:disabled="scope.row.spendingProjectCode=='UM000'">
										<template slot="append">元</template>
									</el-input>
								</template>
							</el-table-column>
							<el-table-column label="状态" align="center" width="150" v-if="slForm.status">
								<template slot-scope="scope">
									{{ folderStatus[slForm.status] }}
								</template>
							</el-table-column>
							<el-table-column width="200" align="center"
								v-if="slForm.status=='SUBMIT'||slForm.status=='SUCCESS'||slForm.status=='FINISH'">
								<template slot="header" slot-scope="scope">
									操作
									<span style="color: red;">(请先保存信息后再上传附件)</span>
								</template>
								<template slot-scope="scope">
									<el-link @click="goUploadFile(scope.row)"> 去查看</el-link>
								</template>
							</el-table-column>
							<el-table-column width="250" align="center" v-else>
								<template slot="header" slot-scope="scope">
									操作
									<span style="color: red;">(请先保存信息后再上传附件)</span>
								</template>
								<template slot-scope="scope">

									<!-- <el-button
										v-if="scope.row.spendingProjectCode!='UM000'&&scope.row.spendingProjectCode!='J0000'"
										type="text" @click="deleteRows(scope.$index,'last')">删除行</el-button> -->
									<el-button v-if="scope.row.id" type="text"
										@click="goUploadFile(scope.row)">去上传文件</el-button>
									<span v-else style="color: red;margin-left: 10px;">(请先保存信息后再上传附件)</span>
								</template>
							</el-table-column>

						</el-table>
						<div class="subtotal">合计上阶段结算金额： {{slForm.lastUsedMoney?slForm.lastUsedMoney:0}}元</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item class="formOptions">
				<!-- <el-button type="primary" @click="addRows()">增加行</el-button> -->
				<el-button type="primary" @click="submitForm('slForm')"
					:disabled="!slForm.id || newDetail || slForm.status=='FINISH'">提交收款申请</el-button>
				<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	// import projectTree from '@/assets/projectTree.json';
	import baseUrl from '@/request/config'
	import {
		getSheetData,
		getProjectConfig,
		saveUpdateOrder,
		saveOrder,
		getOrderByMeetId,
		getOrderById,
		getCollectWay,
		delDetail,
		submitOrder,
		orderMeetingList,
		uploadOrder
	} from '@/request/api'
	export default {
		name: 'supplierSettlement',
		data() {
			return {
				baseUrl,
				projectId: '',
				meetingId: '',
				orderId: '',
				slForm: {
					collectWay: '', // 收款方式
					feeType: '', // 费用类型
					projectName: '', // 项目名称
					supplierName: '', // 供应商名称
					applyMoney: 0, // 本次收款金额
					receiptType: '', // 发票类型
					deliveryNum: '', // 快递单号
					spendingDetailDtos: [{
						detailType: "",
						id: "",
						spendingProjectCode: "UM000",
						money: ''
					}] // 
				},
				newDetail: false,
				rules: {
					collectWay: [{
						required: true,
						message: '请选择收款方式',
						trigger: 'change'
					}],
					feeType: [{
						required: true,
						message: '请选择费用类型',
						trigger: 'change'
					}],
					projectName: [{
						required: true,
						message: '请输入项目名称',
						trigger: 'change'
					}],
					supplierName: [{
						required: true,
						message: '请输入供应商名称',
						trigger: 'change'
					}],
					applyMoney: [{
						required: true,
						message: '请完善本次收款明细',
						trigger: 'change'
					}],
					lastUsedMoney: [{
						required: true,
						message: '请完善上阶段支出明细',
						trigger: 'change'
					}],
					receiptType: [{
						required: true,
						message: '请选择发票类型',
						trigger: 'change'
					}],
					deliveryNum: [{
						required: false,
						message: '请输入快递单号',
						trigger: 'blur'
					}],
					meetingIds: [{
						required: true,
						message: '请选择您要结算的会议场次',
						trigger: 'change'
					}]
				},
				feeTypeEnums: [{
						value: "0",
						label: "会议类"
					},
					{
						value: '1',
						label: "非会议类"
					}
				],
				collectWayEnums: [{
						value: "0",
						label: "一次性收款并结项"
					}, {
						value: "1",
						label: "首次收款"
					}, {
						value: "2",
						label: "阶段收款"
					}, {
						value: "3",
						label: "尾款"
					},
					{
						value: "4",
						label: "据实结算"
					}
				],
				receiptTypeEnums: [{
						value: "0",
						label: "电子发票"
					}, {
						value: "1",
						label: "纸质发票"
					},
					{
						value: "2",
						label: "电子+纸质发票"
					}
				],
				projectTree: [],
				token: '',
				loading: false,
				loadingText: "",
				meetingList: [],
				folderStatus: {
					CREATED: "已创建",
					AI_REVIEW: "预审中",
					AI_REVIEW_ERROR: "预审失败",
					AI_REVIEW_SUCCESS: '预审成功',
					WAIT: '待提交',
					SUBMIT: '待审核',
					ERROR: '审核驳回',
					SUCCESS: "审核通过",
				}
			}
		},
		created() {
			this.projectId = this.$route.query.pId;
			this.meetingId = this.$route.query.mId;
			this.orderId = this.$route.query.orderId ? this.$route.query.orderId : this.slForm.id;
			this.getParams();
		},
		methods: {
			// 获取会议列表
			getMeetingList() {
				let data = {
					orderId: this.orderId ? this.orderId : this.slForm.id,
					projectId: this.projectId,
					meetingId: this.meetingId,
				};
				orderMeetingList(data).then(res => {
					if (res.code == 200) this.meetingList = res.data;
				});
			},
			// 获取结算单信息
			getSheetDataFun() {
				getOrderById({
					projectId: this.projectId,
					meetingId: this.meetingId,
					orderId: this.$route.query.orderId ? this.$route.query.orderId : this.slForm.id,
					collectWay: this.$route.query.collectWay,
					feeType: this.$route.query.feeType
				}).then(res => {
					if (res.code == 200) {
						let resData = JSON.parse(JSON.stringify(res.data));
						for (let i in resData) {
							if (resData[i] == null) resData[i] = '';
						};
						if (resData.collectWay == 2 || resData.collectWay == 3) {
							resData.lastDetail = [];
							// resData.lastUsedMoney = '';
							let array = []
							resData.spendingDetailDtos.map((item, i) => {
								if (item.detailType == "L") {
									resData.lastDetail.push(item);
								} else {
									array.push(item);
								}
							});
							resData.spendingDetailDtos = array;
						}
						if (resData.meetingIds) {
							// resData.meetingIds
							console.log(resData.meetingIds);
						} else {
							resData.meetingIds = [];
						};
						// console.log(resData);
						this.slForm = resData;
						// this.$forceUpdate()
						this.getMeetingList();
					};
				});
			},
			// 近入页面获取必要参数
			getParams() {
				if (this.$route.query.collectWay) {
					this.slForm.collectWay = this.$route.query.collectWay;
				};
				this.getSheetDataFun();
				this.getProjectConfigFun();
				// this.getMeetingList();
				// 获取当前用户token
				this.token = localStorage.getItem('regToken') ? JSON.parse(localStorage.getItem('regToken')).token : JSON
					.parse(localStorage.getItem('token')).token;
			},
			// 获取支出明细类型列表
			getProjectConfigFun() {
				getProjectConfig().then(res => {
					if (res.code == 200) {
						this.projectTree = res.data
					}
				})
			},
			// 导入结算单前
			beforeUploadFun() {
				if (!this.slForm.collectWay) {
					this.$message.error('请先选择收款方式');
					return false;
				};
			},
			// 导入结算单
			importTheStatement(res) {
				if (res.code == 200) {
					this.$message.success('导入成功')
					for (let n in res.data) {
						let nVal = res.data[n];
						// console.log(inData)
						if (this.slForm.lastDetail) {
							nVal.detailType = "L";
							let inData = this.slForm.lastDetail.map(code => code.spendingProjectCode).indexOf(nVal
								.spendingProjectCode);
							if (inData != -1) {
								this.slForm.lastDetail[inData] = nVal;
							} else {
								this.slForm.lastDetail.push(nVal);
							};
						} else {
							nVal.detailType = "C";
							let inData = this.slForm.spendingDetailDtos.map(code => code.spendingProjectCode).indexOf(nVal
								.spendingProjectCode);
							if (inData != -1) {
								this.slForm.spendingDetailDtos[inData] = nVal;
							} else {
								this.slForm.spendingDetailDtos.push(nVal);
							};
						};
					};
					// console.log(JSON.parse(JSON.stringify(this.slForm)))
					this.$forceUpdate();
				};
			},
			// 去上传文件
			goUploadFile(row) {
				// console.log(JSON.parse(JSON.stringify(row)))
				if (row.spendingProjectCode) {
					this.$router.push({
						name: 'fileListPreview',
						query: {
							id: row.id,
							oId: this.slForm.id,
							isView: this.slForm.isView ? this.slForm.isView : ''
						}
					});
				};
			},
			// 增加行
			addRows() {
				if (this.slForm.lastDetail) {
					this.slForm.lastDetail.push({
						detailType: "L"
					})
				} else {
					this.slForm.spendingDetailDtos.push({
						detailType: "C"
					})
				};
				this.newDetail = true;
			},
			// 删除行
			deleteRows(index, type) {
				// 二次提示
				if (type) {
					if (this.slForm.lastDetail.length > 1) {
						this.$confirm(this.slForm.lastDetail[index].id ? `该明细有保存记录, 是否删除?` : `该明细无保存记录, 是否删除?`,
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
							if (this.slForm.lastDetail[index].id) {
								delDetail({
									detailId: this.slForm.lastDetail[index].id
								}).then(res => {
									if (res.code == 200) {
										this.$message({
											type: 'success',
											message: '删除成功!'
										});
										this.slForm.lastDetail.splice(index, 1);
										this.moneyChange(index);
									};
								});
							} else {
								this.slForm.lastDetail.splice(index, 1);
								this.moneyChange(index);
							}
						})
					}
					this.slForm.lastDetail.map(item => {
						if (!item.id) this.newDetail = true;
					})
				} else {
					if (this.slForm.spendingDetailDtos.length > 1) {
						this.$confirm(this.slForm.spendingDetailDtos[index].id ? `该明细有保存记录, 是否删除?` : `该明细无保存记录, 是否删除?`,
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
							if (this.slForm.spendingDetailDtos[index].id) {
								delDetail({
									detailId: this.slForm.spendingDetailDtos[index].id
								}).then(res => {
									if (res.code == 200) {
										this.$message({
											type: 'success',
											message: '删除成功!'
										});
										this.slForm.spendingDetailDtos.splice(index, 1);
										this.moneyChange(index);
									}
								})
							} else {
								this.slForm.spendingDetailDtos.splice(index, 1)
								this.moneyChange(index);
							}
						})
					}
					this.slForm.spendingDetailDtos.map(item => {
						if (!item.id) this.newDetail = true;
					});
				};
			},
			// 金额变化
			lastMoneyChange(index, event) {
				if (event) {
					this.slForm.lastDetail[index].money = event.replace(
						/^(\-)*(\d+)\.(\d{0,2}).*$/, '$1$2.$3');
				};
				let money = 0;
				this.slForm.lastDetail.map(item => {
					money += Number(item.money); //> 0 ? Number(item.money) : 0;
				});
				this.slForm.lastUsedMoney = money;
			},
			moneyChange(index, event) {
				if (event) {
					this.slForm.spendingDetailDtos[index].money = event.replace(
						/^(\-)*(\d+)\.(\d{0,2}).*$/, '$1$2.$3');
				};
				let money = 0;
				this.slForm.spendingDetailDtos.map(item => {
					money += Number(item.money); //> 0 ? Number(item.money) : 0;
				});
				this.slForm.applyMoney = money;
				// this.slForm.totalPrice = `${money}元`;
			},
			// 提交表单
			submitForm(formName) {
				let formType = true;
				let data = JSON.parse(JSON.stringify(this.slForm));
				if (data.lastDetail) {
					data.spendingDetailDtos = [...data.spendingDetailDtos, ...data.lastDetail];
					delete data.lastDetail;
				};
				for (let i in data.spendingDetailDtos) {
					if (!data.spendingDetailDtos[i].spendingProjectCode) {
						this.$alert('请完善所有的支出明细类型', '提示', {
							confirmButtonText: '确定',
							center: true,
							type: "warning"
						});
						formType = false;
						return false;
					} else if (!data.spendingDetailDtos[i].money && data.spendingDetailDtos[i]
						.spendingProjectCode != 'UM000') {
						this.$alert('请填写所有支出明细类型的金额', '提示', {
							confirmButtonText: '确定',
							center: true,
							type: "warning"
						});
						formType = false;
						return false;
					};
				};
				this.$refs[formName].validate(valid => {
					if (!valid) {
						formType = fasle;
						return false;
					}
				});
				this.loadingText = '正在提交···'
				this.loading = true;
				setTimeout(() => {
					console.log(this.slForm.status)
					if (this.slForm.status == "WAIT") {
						// uploadOrder({
						submitOrder({
							orderId: this.slForm.id
						}).then(res => {
							this.loading = false;
							if (res.code == 200) {
								this.$message({
									message: '提交成功',
									type: 'success'
								});
								this.getSheetDataFun();
							} else {
								this.$message({
									message: res.msg,
									type: 'error'
								});
							}
						})
					} else {
						uploadOrder({
							// submitOrder({
							orderId: this.slForm.id
						}).then(res => {
							this.loading = false;
							if (res.code == 200) {
								this.$message({
									message: '提交成功',
									type: 'success'
								});
								this.getSheetDataFun();
							} else {
								this.$message({
									message: res.msg,
									type: 'error'
								});
							}
						})
					}
				}, 1000);
			},
			// 保存信息
			saveInformation(formName) {
				// console.log(this.$refs[formName])
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.slForm));
						this.loadingText = '正在保存···'
						this.loading = true;
						if (data.lastDetail) {
							data.spendingDetailDtos = [...data.spendingDetailDtos, ...data.lastDetail];
							delete data.lastDetail;
						};
						data.spendingDetailDtos.map(item => {
							if (data.collectWay == '0' || data.collectWay == '4') {
								item.detailType = 'L';
							};
							if (!item.money) {
								item.money = 0;
								return item;
							};
							return item;
						});
						for (let i in data.spendingDetailDtos) {
							if (!data.spendingDetailDtos[i].spendingProjectCode) {
								this.$alert('请完善所有的支出明细类型', '提示', {
									confirmButtonText: '确定',
									center: true,
									type: "warning"
								});
								this.loading = false;
								return false;
							} else if (!data.spendingDetailDtos[i].money && data.spendingDetailDtos[i]
								.spendingProjectCode !=
								'UM000') {
								this.$alert('请填写所有支出明细类型的金额', '提示', {
									confirmButtonText: '确定',
									center: true,
									type: "warning"
								});
								this.loading = false;
								return false;
							};
						};
						// console.log(JSON.parse(JSON.stringify(data)));
						saveUpdateOrder(data).then(res => {
							if (res.code == 200) {
								this.loading = false;
								this.newDetail = false;
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.getSheetDataFun();
							} else {
								this.loading = false;
								this.$message({
									message: res.msg,
									type: 'error'
								});
							};
						});
					} else {
						this.$message({
							message: '请完善表单信息',
							type: 'warning'
						});
						return false;
					}
				});
			},
			saveInformation2(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.slForm));
						this.loadingText = '正在保存···'
						this.loading = true;
						if (data.lastDetail) {
							data.spendingDetailDtos = [...data.spendingDetailDtos, ...data.lastDetail];
							delete data.lastDetail;
						};
						data.spendingDetailDtos.map(item => {
							if (data.collectWay == '0' || data.collectWay == '4') {
								item.detailType = 'L'
							};
							if (!item.money) {
								item.money = 0;
								return item;
							};
							return item;
						});
						for (let i in data.spendingDetailDtos) {
							if (!data.spendingDetailDtos[i].spendingProjectCode) {
								this.$alert('请完善所有的支出明细类型', '提示', {
									confirmButtonText: '确定',
									center: true,
									type: "warning"
								});
								this.loading = false;
								return false;
							} else if (!data.spendingDetailDtos[i].money && data.spendingDetailDtos[i]
								.spendingProjectCode !=
								'UM000') {
								this.$alert('请填写所有支出明细类型的金额', '提示', {
									confirmButtonText: '确定',
									center: true,
									type: "warning"
								});
								this.loading = false;
								return false;
							};
						};
						// console.log(JSON.parse(JSON.stringify(data)));
						saveOrder(data).then(res => {
							if (res.code == 200) {
								this.loading = false;
								this.newDetail = false;
								this.$message({
									message: '保存成功',
									type: 'success'
								});
								this.getSheetDataFun();
							} else {
								this.loading = false;
								this.$message({
									message: res.msg,
									type: 'error'
								});
							}
						});
					} else {
						this.$message({
							message: '请完善表单信息',
							type: 'warning'
						});
						return false;
					};
				});
			},
			// 下载结算单模板
			downloadTemplate() {
				let downLodUrl =
					'http://life-oasis.oss-cn-beijing.aliyuncs.com/sign/2024/08/28/ce3c58589fc14b65beed5934f8d27898.xlsx';
				let name = '结算单模板.xlsx';
				const x = new XMLHttpRequest();
				x.open("GET", downLodUrl, true);
				x.responseType = 'blob';
				x.onload = function(e) {
					const url = window.URL.createObjectURL(x.response);
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					a.download = name;
					a.click();
					a.remove();
				};
				x.send();
			}
		}
	}
</script>

<style lang="scss" scoped>
	#supplierSettlement {
		width: 100%;
		height: 100%;
		padding: 20px;

		.top {
			padding: 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.searth {
				display: flex;
				margin-top: 20px;

				.el-form-item {
					display: flex;
					align-items: center;
					margin-right: 20px;

					.el-form-item__content {
						flex: 1;
					}
				}
			}
		}

		::v-deep .slForm {
			padding: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);

			.el-select {
				width: 100%;
			}

			.el-form-item__label {
				padding: 0;
			}

			.titB {
				color: #409EFF;
			}

			.options {
				display: flex;
				align-items: center;

				.first {
					flex: 1;
					margin-right: 30px;
					display: flex;
					align-items: center;

					.el-link {
						margin-right: 20px;
					}
				}
			}

			.subtotal {
				border-bottom: 1px solid #EBEEF5;
				padding: 10px 20px;
				display: flex;
				justify-content: center;
				font-size: 16px;
			}

			.formOptions {
				.el-form-item__content {
					display: flex;
					justify-content: center;

					.el-button {
						margin: 0 20px;
					}
				}
			}
		}
	}

	.el-popover {
		h3 {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		p {
			font-size: 14px;

			span {
				color: red;
			}
		}
	}
</style>